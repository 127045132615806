import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = [
             "",
            // "workout-statistic",
            // "workout-plan",
            // "distance-map",
            // "diet-food-menu",
            // "personal-record",
         ],
         campaign = [
            "voucher",
         ];
       /*   app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
         ],
         email = ["email-compose", "email-inbox", "email-read"],
         shop = [
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "ecom-product-detail",
         ],
         charts = [
            "chart-rechart",
            "chart-flot",
            "chart-chartjs",
            "chart-chartist",
            "chart-sparkline",
            "chart-apexchart",
         ],
         bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
         ],
         plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
			"uc-lightgallery",
         ],
         widget = ["widget-basic"],
         forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
         ],
         table = ["table-bootstrap-basic", "table-datatable-basic"],
         pages = [
            "page-registser",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ],
         error = [
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
         ]; */
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                 


<li className={`${path === "" ? "mm-active" : ""}`}>
                     <Link
                        to="/"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-home-2"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>


                  <li className={`${path === "members" ? "mm-active" : ""}`}>
                     <Link
                        to="/members"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-9"></i>
                        <span className="nav-text">Members</span>
                     </Link>
                  </li>

{/* 
                  <li className={`${path === "sub-agents" ? "mm-active" : ""}`}>
                     <Link
                        to="/sub-agents"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-user-7"></i>
                        <span className="nav-text">Sub Agent</span>
                     </Link>
                  </li> */}


<li className={`${path === "instances" ? "mm-active" : ""}`}>
                     <Link
                        to="/instances"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-smartphone-5"></i>
                        <span className="nav-text">Instances</span>
                     </Link>
                  </li>

                 


                  {/* <li className={`${path === "packs" ? "mm-active" : ""}`}>
                     <Link
                        to="/packs"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-briefcase"></i>
                        <span className="nav-text">Packs</span>
                     </Link>
                  </li> */}


                  <li className={`${path === "feedback" ? "mm-active" : ""}`}>
                     <Link
                        to="/feedback"
                        className="ai-icon"
                        
                     >
                        
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">Feedback</span>
                     </Link>
                  </li>

                
               </MM>
              

               <div className="copyright">
                  <p>
                     <strong>{process.env.REACT_APP_NAME}</strong> ©All Rights Reserved
                  </p>
                  <p>By {process.env.REACT_APP_BY}</p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
