import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import xlsx from "xlsx";
import Moment from "moment";
import { format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";

//import Firebase from "../../../providers/firebaseProvider";

import { database } from "../../../providers/use-auth";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Modal,
  ProgressBar,
  Button,
  Spinner,
  ListGroup,
  Pagination,
} from "react-bootstrap";

import { getProfile } from "../../../store/actions/AuthActions";

import axiosInstance from '../../../services/AxiosInstance';

const InstancePage = ({ props, history }) => {
  const dispatch = useDispatch();
  const [qr, setQr] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state.auth.profile);

  const [instances, setInstances] = useState([]);

  const sort = 500;
  let pagination = Array(Math.ceil(instances.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const [activePage, setActivePage] = useState(0);
  const [tableData, setTableData] = useState([]);



  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [activeInstance, setActiveInstance] = useState(null);
  
  const [assignModal, setAssignModal] = useState(false);

  useEffect(() => {
    dispatch(getProfile());
    loadInstance();
    loadMembers();
  }, [dispatch]);

  useEffect(() => {
    if (instances) {
      setLoading(false);
      setTableData(instances.slice(activePage * sort, (activePage + 1) * sort));
    }
  }, [instances]);

  const loadInstance = async () => {
    const { data } = await axiosInstance.post("reseller/instances");

    if (data.status) {
      setInstances(data.instances);
    }
  };

  const loadMembers = async () => {
    const { data } = await axiosInstance.post("reseller/members");
 
    if (data.status) {
      setMembers(data.members);

      if(data.members.length > 0){
        setSelectedMember(data.members[0].id)
    }
    }
  };
 

  const onClick = (i) => {
    console.log("onClick - " + i);
    setActivePage(i);
    setTableData(instances.slice(i * sort, (i + 1) * sort));
  };


  const onDeleteInstance = async (id) => {
    const { data } = await axiosInstance.post("reseller/delete-instance", {
      instance_id: id,
    });

    if (data.status) {
      //setCampaigns(data.campaigns);
      swal("Delete Instance", data.message, "success");
      loadInstance();
    } else {
      console.log("deleted failed");
      swal("Delete Instance", data.message, "error");
    }
  };

  const onAssign = async (event) => {
    event.preventDefault();    

    if(selectedMember == ""){
        swal("Assign Instance", "Member not found", "error");
        return;
    }

    const { data } = await axiosInstance.post("reseller/assign-instance", {
        instance_id: activeInstance.id,
        member_id:selectedMember
      });
  
      if (data.status) {
        //setCampaigns(data.campaigns);
        swal("Assign Instance", data.message, "success");
        loadInstance();

        setAssignModal(false);
        setActiveInstance(null);
        loadMembers();

      } else {
        
        swal("Assign Instance", data.message, "error");
      }

  }


  const assignInstance = async (instance) => {
    
    setActiveInstance(instance);
    setAssignModal(true);

  };

  return (
    <>


<Modal className="modal fade" show={assignModal} onHide={setAssignModal}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAssign}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Assign Instance</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAssignModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <p>
                      Assign instance to
                    </p>
                    <br />

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Member</label>
                      <div className="contact-name">
                        <select
                          defaultValue={selectedMember}
                          className="form-control"
                          onChange={(e)=>setSelectedMember(e.target.value)}
                        //   style={{
                        //     lineHeight: "40px",
                        //     color: "#7e7e7e",
                        //     paddingLeft: " 15px",
                        //   }}
                        >

                            {members.map((m,i)=>{
                                return  <option key={i} value={m.id}>{m.name+"("+m.email+")"}</option>
                              })}

                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setAssignModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Assign
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>


      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title className="mr-auto p-2">Whatsapp Instants</Card.Title>

              <div class="d-flex justify-content-end"></div>
            </Card.Header>
            <Card.Body>
              {instances.length == 0 ? (
                <p>No Instance Found</p>
              ) : (
                <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table id="example" className="display w-100 dataTable">
                      <thead>
                        <tr role="row">
                        <th>Sr No. </th>
                          <th>Instance Key </th>
                          <th>Name</th>
                          <th>User</th>
                          <th>Instance Status</th>
                          <th>Created At</th>
                          <th>Expire At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((instance, i) => (
                          <tr key={instance.code}>
                            <td>{i+1}</td>
                            <td>
                              <strong className={instance.isDeleted?"text-danger" :"text-success"}>
                                {instance.code}
                              </strong>
                            </td>

                            <td>{instance.name ?? ""}</td>

                            <td>
                              {instance.userId ? instance.userId.name : ""}
                            </td>

                            <td>
                              <div className="">
                                {instance.status ?? ""}
                                <br />
                                <small>{instance.message ?? ""}</small>
                              </div>
                            </td>

                            <td>
                              {format(
                                new Date(instance.createdAt),
                                "dd/MM/yyyy H:mma"
                              )}
                            </td>

                            <td>
                              {instance.expire?format(
                                new Date(instance.expire),
                                "dd/MM/yyyy H:mma"
                              ):""}
                            </td>

                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="table-dropdown icon-false"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                 

                                 {instance.userId?
                                  <Dropdown.Item
                                    as="button"
                                    onClick={(e) =>
                                      onDeleteInstance(instance.id)
                                    }
                                  >
                                    Delete From Member
                                  </Dropdown.Item>:
                                   <Dropdown.Item
                                   as="button"
                                   onClick={(e) =>
                                    assignInstance(instance)
                                   }
                                 >
                                   Assign To User
                                 </Dropdown.Item>
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr role="row">
                        <th>Sr No. </th>
                          <th>Instance Key </th>
                          <th>Name</th>
                          <th>User</th>
                          <th>Instance Status</th>
                          <th>Created At</th>
                          <th>Expire At</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {activePage * sort + 1} to&nbsp;
                        {instances.length < (activePage + 1) * sort
                          ? instances.length
                          : (activePage + 1) * sort}
                        &nbsp;of {instances.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination
                          className="pagination-primary pagination-circle"
                          size="lg"
                        >
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePage > 1 && onClick(activePage - 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-left" />
                            </Link>
                          </li>
                          {pagination.map((number, i) => (
                            <Pagination.Item
                              key={"page-" + i}
                              className={activePage === i ? "active" : ""}
                              onClick={() => onClick(i)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePage + 1 < pagination.length &&
                              onClick(activePage + 1)
                            }
                          >
                            <Link className="page-link" to="#">
                              <i className="la la-angle-right" />
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InstancePage;
